var modal = [0,0];
$(function(){
    // 「.modal-open」をクリック
    $('.modal-open').click(function(){


        // モーダルコンテンツのIDを取得
		
        //var modal = '#' + $(this).attr('data-target');
        // モーダルコンテンツの表示位置を設定
		modal.unshift('#' + $(this).attr('data-target'));
		modal.pop();
        modalResize();
		if(modal[1] !== "0"){
            $(modal[1]).fadeOut('slow');
		}

		if(!($('.modal-overlay').length)){
        // オーバーレイ用の要素を追加
        $('body').append('<div class="modal-overlay"></div>');
        // オーバーレイをフェードイン
        $('.modal-overlay').fadeIn();
		}

         // モーダルコンテンツフェードイン
//        $(modal).fadeIn('slow');
        $(modal[0]).fadeIn('slow');

        // 「.modal-overlay」あるいは「.modal-close」をクリック
        $('.modal-overlay, .modal-close').off().click(function(){
            // モーダルコンテンツとオーバーレイをフェードアウト
            $(modal[0]).fadeOut('slow');
            $('.modal-overlay').fadeOut('slow',function(){
                // オーバーレイを削除
                $('.modal-overlay').remove();
            });
        });

        // リサイズしたら表示位置を再取得
        $(window).on('resize', function(){
            modalResize();
        });

        // モーダルコンテンツの表示位置を設定する関数
        function modalResize(){
            // ウィンドウの横幅、高さを取得
            var w = $(window).width();
            var h = $(window).height();

            // モーダルコンテンツの表示位置を取得
            var x = (w - $(modal[0]).outerWidth(true)) / 2;
            var y = (h - $(modal[0]).outerHeight(true)) / 2;

            // モーダルコンテンツの表示位置を設定
            $(modal[0]).css({'left': x + 'px','top': y + 'px'});
        }

    });
});