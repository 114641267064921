//controller = new ScrollMagic.Controller();
controller = new ScrollMagic.Controller();
$('.js-inView').each(function () {
  var currentStrong = this;
  // Create a scene for each project
  var sceneEach = new ScrollMagic.Scene({
    triggerElement: currentStrong,
    reverse: false,
    triggerHook: 0.7
  })
    .setClassToggle(currentStrong, 'is-show')
    .addTo(controller)
});

/////////////
$('a[href^="#"]').click(function () {
  var speed = 800;
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var headerHeight = 0;
  var position = target.offset().top - headerHeight;
  $('body,html').animate({
    scrollTop: position
  }, speed, 'easeOutExpo');
  return false;
});


//if ((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
//	// スマホ・タブレット用javascript
//} else {
//}
