var navTrigger = $('#js-gnavTrigger'),
	navBody = $('#js-gnavBody'),
	navLink = $('#js-gnaviActive');

if (!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {

	navTrigger.on('click', function () {
		$(this).toggleClass('is-active');
		$(navBody).toggleClass('is-opened');
	});

} else {

	navTrigger.on('touchstart', function () {
		$(this).toggleClass('is-active');
		$(navBody).toggleClass('is-opened');
	});

	navLink.on('touchend', function () {
		$(navTrigger).toggleClass('is-active');
		$(navBody).toggleClass('is-opened');
	});
}